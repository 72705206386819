// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bugtracker-js": () => import("./../../../src/pages/bugtracker.js" /* webpackChunkName: "component---src-pages-bugtracker-js" */),
  "component---src-pages-ice-cream-selector-js": () => import("./../../../src/pages/ice-cream-selector.js" /* webpackChunkName: "component---src-pages-ice-cream-selector-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motor-city-wash-works-js": () => import("./../../../src/pages/motor-city-wash-works.js" /* webpackChunkName: "component---src-pages-motor-city-wash-works-js" */),
  "component---src-pages-peapod-digital-labs-js": () => import("./../../../src/pages/peapod-digital-labs.js" /* webpackChunkName: "component---src-pages-peapod-digital-labs-js" */),
  "component---src-pages-quarantine-movie-release-schedule-js": () => import("./../../../src/pages/quarantine-movie-release-schedule.js" /* webpackChunkName: "component---src-pages-quarantine-movie-release-schedule-js" */),
  "component---src-pages-robot-culture-js": () => import("./../../../src/pages/robot-culture.js" /* webpackChunkName: "component---src-pages-robot-culture-js" */)
}

